import { useEffect, useRef } from 'react'

/*
  Meant as a stop-gap solution until we can upgrade to Next 14 which apparently
  doesn't need the ref hack here.
*/
export const useEffectOnce = (effect: () => void | (() => void)) => {
  const isLoaded = useRef(false)

  useEffect(() => {
    if (isLoaded.current === false) {
      isLoaded.current = true
      effect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
